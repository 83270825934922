<template>
    <div class="user-info page-container">
        <BreadcrumbBack parent-title="用户管理" title="用户信息" back-path="/userManager"/>
        <el-form :model="form" :rules="rules" label-width="160px" label-position="left" ref="form" class="form">
            <el-form-item label="账号" prop="username">
<!--                <el-input placeholder="请输入账号" v-model="form.username" clearable></el-input>-->
<!--                <div class="tips">-->
<!--                    请输入8-20个字符，以字母开头，支持大写字母、小写字母、数字组合-->
<!--                </div>-->
                {{ form.username }}
            </el-form-item>


            <el-form-item v-if="!isEdit.editPwd"  label="密码">
                ****** <i class="el-icon-edit" @click="() => {this.isEdit.editPwd = true}"
                   style="cursor: pointer;margin-left: 10px;"></i>
            </el-form-item>
            <div v-else>
                <el-form-item label="密码" prop="password">
                    <el-input placeholder="请输入" v-model.trim="form.password" type="password" show-password/>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPassword">
                    <div class="flex_container">
                        <el-input placeholder="请输入" v-model.trim="form.confirmPassword" type="password" show-password/>
                        <el-button size="small" @click="() => {this.isEdit.editPwd = false}" style="margin-left: 10px;transform: translateY(3px);">取消</el-button>
                    </div>
                </el-form-item>
            </div>
            <el-form-item label="客户名称" prop="orgName">
                <el-input placeholder="请输入客户名称" v-model="form.orgName" clearable></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="contactPerson">
                <el-input placeholder="请输入联系人姓名" v-model="form.contactPerson" clearable></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
                <el-input placeholder="请输入手机号" v-model="form.phone" clearable></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="mail">
                <el-input placeholder="请输入邮箱" v-model="form.mail" clearable></el-input>
            </el-form-item>
            <el-form-item prop="regionList">
                <span slot="label"> 资源池
                    <el-tooltip slot="suffix" content="提示:可选择多个资源池，配额: 0代表无限制" placement="top">
                        <span class="vol-tip"><i class="fa fa-question-circle-o"
                                                 style="font-size: 16px;color: #999;"></i></span>
                    </el-tooltip>  </span>
                <div v-show="!isEdit.regionList" style="display: flex;justify-content: left;align-items: baseline;">
                    <el-table
                        :data="form.regionList"
                        border>
                        <el-table-column label="资源池" prop="regionName"></el-table-column>
                        <el-table-column label="配额" prop="storageSpecs">
                            <template slot-scope="scope">
                                {{ scope.row.storageSpecs }}
                                {{ {4:'GB',5:'TB',6:'PB'}[scope.row.storageUnit] }}
                            </template>
                        </el-table-column>
                        <el-table-column label="存储规则" prop="policyId"></el-table-column>
                    </el-table>
                    <i class="iconfont2 iconbianji" style="cursor: pointer;" @click="showRegionListEdit"></i>
                </div>
                <div v-show="isEdit.regionList">
                    <RegionList  ref="regionListRef" :is-create="false"/>
<!--                    <el-button type="text" @click="handleRegionList">确定</el-button>-->
<!--                    <el-button type="text" @click="() => {this.isEdit.regionList = false;}">取消</el-button>-->
                </div>
            </el-form-item>


            <template v-if="isFold">
                <el-form-item label="用户性质">
                    <el-radio-group v-model="form.orgType" :disabled="!!detail.orgType">
                        <el-radio :label="'BUSINESS'">企业</el-radio>
                        <el-radio :label="'GOVERNMENT'">政府</el-radio>
                    </el-radio-group>
                </el-form-item>
                <div v-if="form.orgType === 'BUSINESS'">
                    <el-form-item label="统一社会信用代码" prop="unifiedSocialCreditCode">
                        <el-input placeholder="请输入统一社会信用代码" v-model="form.unifiedSocialCreditCode" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="营业执照图片">
                        <el-upload
                            :action="'/api/img/upload'"
                            :multiple="false"
                            :show-file-list="false"
                            :on-error="() => {
                                this.pictureLoading.businessLicense = false
                            }"
                            :on-success="(response, file, fileList) => {
                              handlePicture(response, file, 'businessLicense');
                            }"
                            :before-upload="(file, fileList) => {
                              handlePictureBefore(file, 'businessLicense');
                            }"
                            class="uploadPicture">
                            <ImageUser v-if="form.businessLicense" :id="form.businessLicense"/>
                            <i v-else-if="pictureLoading.businessLicense" class="el-icon-loading"></i>
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                        <div class="tips">
                            如果企业已经开始使用三证合一后的新证件，只需要上传【营业执照图片】即可；<br/>
                            支持的图片格式包括JPG、JPEG和PNG；上传的图片需清晰完整；<br/>
                            图片大小不超过20M
                        </div>
                    </el-form-item>
                    <el-form-item label="税务登记图片">
                        <el-upload
                            action="/api/img/upload"
                            :multiple="false"
                            :show-file-list="false"
                            :on-error="() => {
                                this.pictureLoading.taxRegistration = false
                            }"
                            :on-success="(response, file, fileList) => {
                              handlePicture(response, file, 'taxRegistration');
                            }"
                            :before-upload="(file, fileList) => {
                              handlePictureBefore(file, 'taxRegistration');
                            }"
                            class="uploadPicture">
                            <ImageUser v-if="form.taxRegistration" :id="form.taxRegistration"/>
                            <i v-else-if="pictureLoading.taxRegistration" class="el-icon-loading"></i>
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                        <div class="tips">
                            支持的图片格式包括JPG、JPEG和PNG；上传的图片需清晰完整；<br/>
                            图片大小不超过20M
                        </div>
                    </el-form-item>

                </div>
                <div v-if="form.orgType === 'GOVERNMENT'">
                    <el-form-item label="政府机构编码" prop="governmentCode">
                        <el-input placeholder="请输入政府机构编码" v-model="form.governmentCode" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="政府类别" prop="governmentType">
                        <el-input placeholder="请输入政府类别" v-model="form.governmentType" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="行政级别" prop="administrativeLevel">
                        <el-input placeholder="请输入行政级别" v-model="form.administrativeLevel" clearable></el-input>
                    </el-form-item>
                </div>

                <div v-if="!!form.orgType">
                    <el-form-item label="联系人身份证号" prop="contactPersonIdCard">
                        <el-input type="text" placeholder="请输入联系人身份证号" v-model="form.contactPersonIdCard"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证人像面图片">
                        <el-upload
                            action="/api/img/upload"
                            :multiple="false"
                            :show-file-list="false"
                            :on-error="() => {
                                this.pictureLoading.idCardFrontImgId = false
                            }"
                            :on-success="(response, file, fileList) => {
                                  handlePicture(response, file, 'idCardFrontImgId');
                                }"
                            :before-upload="(file, fileList) => {
                                  handlePictureBefore(file, 'idCardFrontImgId');
                                }"
                            class="uploadPicture">
                            <ImageUser v-if="form.idCardFrontImgId" :id="form.idCardFrontImgId"/>
                            <i v-else-if="pictureLoading.idCardFrontImgId" class="el-icon-loading"></i>
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                        <div class="tips">
                            支持的图片格式包括JPG、JPEG和PNG；上传的图片需清晰完整；<br/>
                            图片大小不超过20M
                        </div>
                    </el-form-item>
                    <el-form-item label="身份证国徽面图片">
                    <el-upload
                        action="/api/img/upload"
                        :multiple="false"
                        :show-file-list="false"
                        :on-error="() => {
                                this.pictureLoading.idCardBackImgId = false
                            }"
                        :on-success="(response, file, fileList) => {
                              handlePicture(response, file, 'idCardBackImgId');
                            }"
                        :before-upload="(file, fileList) => {
                              handlePictureBefore(file, 'idCardBackImgId');
                            }"
                        class="uploadPicture">
                        <ImageUser v-if="form.idCardBackImgId" :id="form.idCardBackImgId"/>
                        <i v-else-if="pictureLoading.idCardBackImgId" class="el-icon-loading"></i>
                        <i v-else class="el-icon-plus"></i>
                    </el-upload>
                    <div class="tips">
                        支持的图片格式包括JPG、JPEG和PNG；上传的图片需清晰完整；<br/>
                        图片大小不超过20M
                    </div>
                </el-form-item>
                </div>
            </template>
            <div class="heightChoose" @click="isFold = !isFold">高级选项<i
                :class="[!isFold ? 'el-icon-arrow-down' : 'el-icon-arrow-up']"></i></div>
            <el-form-item label="">
                <el-button size="small" type="primary" :loading="loading" @click="handleSubmit()"> 提交</el-button>
                <el-button size="small" :disabled="loading" @click="$router.back()"> 取消</el-button>
            </el-form-item>
        </el-form>
        <!--    <agreement v-if="isRead" :title="readTitle" @close="isRead = false"></agreement>-->
    </div>
</template>
<script>
import {validPwd, validCreditCode, validUserName, validPhone, validCard} from '../../utils/validators'
// import agreement from '@/components/agreement'
import {mapActions, mapState} from 'vuex'
import BreadcrumbBack from "@/views/components/BreadcrumbBack";
// import { passwordEncrypt } from '@/assets/js/utils'
import RegionList from "./component/RegionList";
import ImageUser from "./component/ImageUser";

export default {
    name: 'createUser',
    components: {
        // agreement,
        BreadcrumbBack,
        RegionList,
        ImageUser
    },
    computed: {},
    data() {
        const validateUserName = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入账号'))
            } else if (value.length>20 || value.length < 8) {
                callback(new Error('请输入8-20个字符'))
            } else if (!/^[a-zA-Z][a-zA-Z\d]{7,19}$/.test(value)) {
                callback(new Error('请字母开头，支持大写字母、小写字母、数字组合'))
            } else {
                callback()
            }
        }
        const validatePwd = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'))
            } else if (validPwd(value)) {
                callback(new Error(validPwd(value)))
            } else {
                if (this.form.confirmPassword !== 'confirmPassword') {
                    this.$refs.form.validateField('confirmPassword')
                }
                callback()
            }
        }
        const validatePwd2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'))
            } else if (value != this.form.password) {
                callback(new Error('两次输入密码不一致'))
            } else {
                callback()
            }
        }
        const validateEmail = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入邮箱'))
            } else if (!/[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/.test(value)) {
                callback(new Error('邮箱格式错误'))
            } else {
                callback()
            }
        }
        const validateCreditCode = (rule, value, callback) => {
            if (value === '') {
                callback()
            } else if (!validCreditCode(value)) {
                callback(new Error('信用代码格式不正确'))
            } else {
                callback()
            }
        }
        const validatePhone = (rule, value, callback) => {
            if (value === '') {
                callback()
            } else if (!validPhone(value)) {
                callback(new Error('手机格式不正确'))
            } else {
                callback()
            }
        }
        const validateCard = (rule, value, callback) => {
            if (value === '') {
                callback()
            } else if (!validCard(value)) {
                callback(new Error('联系人身份证号格式不正确'))
            } else {
                callback()
            }
        }

        return {
            placementList: [],

            editPass: '',
            form: {
                orgType: 'BUSINESS',// 用户性质
                username: '',
                password: '',
                confirmPassword: '',
                orgName: '',
                contactPerson: '',
                phone: '',
                mail: '',
                regionList: [],
                unifiedSocialCreditCode: '',
                businessLicense: '',
                taxRegistration: '',
                governmentCode: '',
                governmentType: '',
                administrativeLevel: '',
                contactPersonIdCard: '',
                idCardFrontImgId: '',
                idCardBackImgId: '',
            },
            defaultRegionList: [],
            rules: {
                // username: [{required: true, validator: validateUserName, trigger: 'blur'}],
                password: [{required: true, validator: validatePwd, trigger: 'blur'}],
                confirmPassword: [{required: true, validator: validatePwd2, trigger: 'blur'}],
                orgName: [{required: true, message: '请输入客户名称', trigger: 'blur'}],
                contactPerson: [{required: true, message: '请输入联系人姓名', trigger: 'blur'}],
                phone: [{required: true, message: '请输入手机号', trigger: 'blur'}, {validator: validatePhone, trigger: 'blur'}],
                mail: [{required: true, validator: validateEmail, trigger: 'blur'}],
                unifiedSocialCreditCode: [{validator: validateCreditCode, trigger: 'blur'}],
                contactPersonIdCard: [{validator: validateCard, trigger: 'blur'}],
            },
            picture: {
                businessLicense: '',
                taxRegistration: '',
                idCardFrontImgId: '',
                idCardBackImgId: '',
            },
            isFold: false,
            noAgreeTips: '',
            isRead: false,
            protocolList: '',
            readTitle: '',
            loading: false,
            pictureLoading: {
                businessLicense: false, // 营业执照
                taxRegistration: false, // 税务登记
                idCardFrontImgId: false, // 身份证
                idCardBackImgId: false,
            },
            detail: {

            },
            isEdit: {
                regionList: false,
                editPwd: false
            }
        }
    },
    created() {
        // this.getProtocolList()
        // this.getlistPlacementRules()
        this.getInfo()
    },
    methods: {
        // ...mapActions('user', ['getProtocol', 'register']),
        // ...mapActions('userManage', ['listPlacementRules']),
        getInfo(id = this.$route.query.id) {
            this.$get('/admin/getAccountUser?accountUserId=' + id)
                .then((res) => {
                    if (res.code === '200') {
                        this.form = res.data
                        this.detail = {...res.data}
                        // this.form.orgType = res.data.orgType || 'BUSINESS'
                        if (res.data.regionList) {
                            this.defaultRegionList = res.data.regionList

                            this.$refs.regionListRef.initList(JSON.parse(JSON.stringify(res.data.regionList)))
                            //
                            // this.$set(this.form, 'regionList', res.data.regionList)
                        }
                    }
                })
        },
        // 修改资源池点击确定
        handleRegionList () {
            let regionList = this.$refs.regionListRef.getRegionCheckedList();
            this.defaultRegionList = regionList
            this.form.regionList = regionList
            this.isEdit.regionList = false
        },
        // 切换到资源池编辑模式
        showRegionListEdit () {
            const {defaultRegionList = []} = this
            this.$refs.regionListRef.initList(JSON.parse(JSON.stringify(defaultRegionList)))
            this.isEdit.regionList = true
        },
        back() {
            this.$router.push({name: 'userManage'})
        },
        getlistPlacementRules() {
            // this.listPlacementRules().then((res) => {
            //     this.placementList = res.data.data
            // })
        },
        getProtocolList() {
            this.getProtocol({type: '0'}).then((res) => {
                this.protocolList = res.data.date.filter((item) => item.ischeck == 1)
            })
        },
        handlePictureBefore(file, field) {
            console.log(file)
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 20

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 、JPEG、PNG格式!')
                return false
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 20MB!')
                return false
            }
            this.pictureLoading[field] = true
            return true
        },

        handlePicture(res, file, name) {
            this.pictureLoading[name] = false
            if (res.code === '200') {
                this.form[name] = res.data
            }
        },
        lookAgreement(title) {
            this.readTitle = title
            this.isRead = true
        },
        handleSubmit() {
            if (this.loading) {
                return
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.loading) {
                        return
                    }

                    let regionList = this.$refs.regionListRef.getRegionCheckedList();
                    if (!regionList || regionList.length === 0) {
                        // this.$message.warning('请选择资源池')
                        return;
                    }
                    let data = {
                        ...this.form,
                        regionList,
                        accountUserId: this.form.bid,
                        // orgType: this.orgType
                    }
                    this.loading = true
                    this.$postJson('/admin/updateAccountUser', data)
                        .then(res => {
                            this.$router.push('/userManager')
                        })
                        .finally(() => {
                            this.loading = false
                        })

                    // var formData = new FormData()
                    // var data = {
                    //   ...this.form,
                    //   password: passwordEncrypt(this.form.password),
                    //   confirmPassword: passwordEncrypt(this.form.confirmPassword),
                    // }
                    // for (var i in data) {
                    //   formData.append(i, data[i])
                    // }
                    // this.register(formData)
                    //   .then((res) => {
                    //     this.$router.push({ name: 'userManage' })
                    //   })
                    //   .finally(() => {
                    //     this.loading = false
                    //   })
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.user-info {
    padding: 24px 32px;
    background: #fff;

    .el-input {
        width: 320px;
    }

    .tips {
        line-height: 20px;
        font-size: 14px;
        color: #9d9d9d;
    }

    .heightChoose {
        margin-bottom: 20px;
        font-size: 14px;
        color: #006bff;
        cursor: pointer;

        i {
            font-size: 18px;
            margin-left: 4px;
        }
    }

    .registerAgree {
        color: #888;
        margin-top: 10px;

        span {
            color: #5890e4;
            cursor: pointer;
        }
    }

    /deep/ {
        .el-form-item__content {
            text-align: left;
        }
    }
}
</style>
